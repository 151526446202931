<template>
  <el-dialog title="Factura" :visible.sync="showPopup" :fullscreen="true" append-to-body :show-close="false"
    :close-on-press-escape="false">
    <span style="color:red" v-if="this.mode == 'stornare'">Stornare factura {{this.selectedObject.NumeSerieFacInitiala}} {{this.selectedObject.NumarInitial}} din {{this.$options.filters.Data(this.selectedObject.DataFactura)}}</span>
    <el-form label-position="top" :inline="false" :rules="rules" label-width="100%" :model="selectedObject"
      ref="my-form" @submit.prevent="save" v-loading="loadingVisible" size="mini">
      <!-- {{this.Info.ProduseExistentePtSelect.length}}+
      <div v-for="linie in this.Info.ProduseExistentePtSelect"> {{linie}}</div>
      --------
      <div v-for="linie in this.Info.ProdusePtSelect"> {{linie.Nume}}</div> -->
      <!-- <pre>{{this.Info.ProdusePtSelectExi}}</pre> -->
      <el-row :gutter="15" style="margin-bottom: 1px; display: flex; align-items: flex-end;">
        <el-col :md="3">
          <el-form-item label="Client" prop="NumeClient" size="mini">
            <div class="input-group full-width">
              <el-select 
              tabindex=0
              ref="focusMe" 
              :disabled="this.mode == 'stornare'" size="mini" v-model="selectedObject.NumeClient" @change="handleSelectClient" remote
                :remote-method="handleFind" filterable>
                <el-option v-for="item in Info.clienti" :key="item.Id + item.CodFiscal" id="client_cif"
                  :name="item.CodFiscal" :label="item.Nume" :value="item.Id">
                </el-option>
              </el-select>
              <div class="btn-a input-group-append">
                <el-button tabindex="-1" :disabled="this.mode == 'stornare'" plain size="mini" type="success" icon="el-icon-plus" @click="show_add_new_client"
                  class="success" />
              </div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :md="2">
          <el-form-item label="Internet">
            <el-checkbox style="color:white"  tabindex="-1"  v-model="selectedObject.Internet"
              @change="handleSelectInternet">Internet
            </el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :md="4">
          <el-form-item label="Observatii client" >
            <el-input rows="2" style="overflow-y: scroll;" size="mini" type="textarea" class="full-width" v-model="selectedObject.DescriereClient" v-show="selectedObject.DescriereClient != ''" :readonly="true" />
          </el-form-item>
        </el-col>
        <!-- <el-col :md="3">
          <el-form-item label="CIF">
            <el-input class="full-width" v-model="selectedObject.CifClient" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :md="3">
          <el-form-item label="Reg. Com.">
            <el-input class="full-width" v-model="selectedObject.RegComClient" :disabled="true" />
          </el-form-item>
        </el-col> -->
        <el-col :md="3">
          <el-form-item label="Curs" prop="Curs">
            <div class="full-width input-group">
              <el-input-number size="mini" v-model="selectedObject.Curs" :precision="4" :min="0" controls-position="right"
                :step="0.01" @change="handleChangeCurs()" />
              <div class="btn-a input-group-append">
                <el-button tabindex="-1" style="margin-right: 16px" plain size="mini" type="primary" icon="el-icon-cloudy"
                  @click="selecteazaDataCurs()"></el-button>
                <el-date-picker :picker-options="datePickerOptions" popper-class="my-popover george"
                  style="display: none" ref="refDataCurs" v-model="selectedObject.DataCurs" type="date"
                  value-format="yyyy-MM-dd" format="dd.MM.yyyy" />
              </div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :md="3">
          <el-form-item label="Serie" prop="IdSerie">
            <el-select class="full-width" :disabled="mode!='add'" v-model='selectedObject.IdSerie' filterable @change="handleSelectSerie">
              <el-option v-for="item in Info.serii" :key="item.Id" :label="item.Serie" :value="item.Id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="3">
          <el-form-item label='Nr.'>
            <el-input class='full-width' v-model='selectedObject.Numar' :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :md="3">
          <el-form-item label="Gestiune" prop="IdGestiune" size="mini">
            <el-select :disabled="this.mode != 'add'" size="mini" class="full-width" v-model="selectedObject.IdGestiune"
              @change="handleSelectGestiune()" filterable>
              <el-option v-for="item in Info.gestiuni" :key="item.Id + item.Nume" :label="item.Nume" :value="item.Id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="3">
          <el-form-item label="Data emitere" prop="DataFactura" size="mini">
            <div class="full-width">
              <el-date-picker v-model="selectedObject.DataFactura" 
              type="date" value-format="yyyy-MM-dd"
              ref="focusData" 
              format="dd.MM.yyyy" @change="handleSelectDataFactura" />
            </div>
          </el-form-item>
        </el-col>
        </el-row>
        <el-row :gutter="15">
        <el-col :md="3">
          <el-form-item label="Scadenta" prop="Scadenta" size="mini">
            <div class="full-width">
              <el-date-picker v-model="selectedObject.Scadenta" type="date" value-format="yyyy-MM-dd"
                format="dd.MM.yyyy" :default-value="null" />
            </div>
          </el-form-item>
        </el-col>
        <el-col :md="3">
          <el-form-item label="Discount %" prop="Discount" size="mini">
            <el-input-number class="full-width" v-model="selectedObject.Discount" controls-position="right"
              :precision="0" :min="0" :step="1" :max="100" @change="totalProduse()" />
          </el-form-item>
        </el-col>
        <el-col :md="3">
          <el-form-item label="Delegat" prop="IdDelegat" size="mini">
            <div class="full-width input-group">
              <el-select size="mini" v-model="selectedObject.IdDelegat" filterable>
                <el-option label="Fara" value="-1"></el-option>
                <el-option v-for="item in Info.delegati" :key="item.Id + item.Name" :label="item.Nume" :value="item.Id">
                </el-option>
              </el-select>
              <div class="btn-a input-group-append">
                <el-button tabindex="-1" plain size="mini" type="success" icon="el-icon-plus" @click="show_add_new_delegat"
                  class="success"></el-button>
              </div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :md="3">
          <el-form-item label="Agent" prop="IdAgent" size="mini">
            <div class="full-width input-group">
              <el-select size="mini" v-model="selectedObject.IdAgent" filterable>
                <el-option label="Fara" value="-1"></el-option>
                <el-option v-for="item in Info.agenti" :key="item.Id + item.Denumire" :label="item.Denumire"
                  :value="item.Id">
                </el-option>
              </el-select>
              <div class="btn-a input-group-append">
                <el-button tabindex="-1" plain size="mini" type="success" icon="el-icon-plus" @click="show_add_new_agent"
                  class="success"></el-button>
              </div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :md="3">
          <el-form-item label="Transport" prop="IdTransport" size="mini">
            <div class="input-group full-width">
              <el-select size="mini" v-model="selectedObject.IdTransport" filterable>
                <el-option label="Fara" value="-1"></el-option>
                <el-option v-for="item in Info.transportatori" :key="item.Id + item.Denumire" :label="item.Denumire"
                  :value="item.Id"></el-option>
              </el-select>
              <div class="btn-a input-group-append">
                <el-button tabindex="-1" plain size="mini" type="success" icon="el-icon-plus" @click="show_add_new_transport"
                  class="success"></el-button>
              </div>
            </div>
          </el-form-item>
        </el-col>
         <el-col :md="3">
          <el-form-item label="Tip incasare" prop="TipIncasare" size="mini">
            <el-select  v-model="selectedObject.TipIncasare" filterable  class="full-width">
              <el-option label="B" value="B"></el-option>
              <el-option label="C" value="C"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="3">
          <el-form-item label="Cont bancar" prop="IdContBancar" size="mini">
            <el-select size="mini" v-model="selectedObject.IdContBancar" filterable class="full-width">
              <el-option v-for="item in Info.conturi_bancare" :key="item.Id + item.Nume" :label="item.Nume"
                :value="item.Id"></el-option>
            </el-select>
          </el-form-item>
        </el-col> 
      </el-row>
      <el-row>
        <el-col :md="24">
          <el-card shadow="hover"  v-show="selectedObject.IdGestiune !== '' && selectedObject.Curs > 0">
            <div slot="header" class="clearfix" style="padding: 0px">
              <span>Produse</span>
            </div>
            <el-table :header-cell-style="{ background: '#393E46' }"  class="produse" :data="selectedObject.Produse" :key="refreshTable">
              <el-table-column v-if="this.Eroare == true" width="28px">
                <template slot-scope="scope">
                  <el-tooltip 
                    v-if="selectedObject.Produse[scope.$index].Eroare != '' " 
                    :content="selectedObject.Produse[scope.$index].Eroare" 
                    :tabindex="-1" 
                  > 
                    <el-button size="mini" type="danger" icon="el-icon-error" circle style="padding: 2px;"/>
                  </el-tooltip>                   
                
                    <el-button  v-else
                    :tabindex="-1"  size="mini" type="success" icon="el-icon-success" circle style="padding: 2px;"/>
                  
                </template>
              </el-table-column>
              
              
              <!-- <el-table-column min-width="190px" label="Produs">
                <template slot-scope="scope">
                  <el-form-item :prop="'Produse[' + scope.$index + '].NumeProdus'"
                    :rules="{ required: true,  message: 'Campul este obligatoriu', trigger: 'blur', }">
                    <div class="input-group full-width">
                      <el-select size="mini" class="produs-input"
                        v-model="selectedObject.Produse[scope.$index].IdProdus"
                        @change="handleSelectProdus(scope.$index)" 
                        :ref="'focusProdus[' + scope.$index + ']'"
                        filterable>
                        <el-option v-for="item in Info.ProdusePtSelect" :key="item.Id" :id="selectedObject.IdGestiune == '' ||
                        selectedObject.IdGestiune == null ||
                        selectedObject.IdGestiune == undefined ? '' : 'produs_cu_stoc'"
                          :name="typeof item.StocFinal != 'undefined' ? 'Stoc: ' + (item.StocFinal) : ''" 
                          :label="item.Nume" 
                          :value="item.Id">
                        </el-option>
                      </el-select>
                      <div v-if="selectedObject.Produse[scope.$index].IdProdus != 0" class="btn-a input-group-append">
                        <el-button tabindex="-1" plain size="mini" type="success" icon="el-icon-plus"
                          @click="show_add_new_produs(scope.$index)" class="success"></el-button>
                      </div>
                    </div>
                  </el-form-item>
                </template>
              </el-table-column> -->
              <el-table-column min-width="190px" label="Produs">
                <template slot-scope="scope">
                  <el-form-item :prop="'Produse[' + scope.$index + '].NumeProdus'"
                    :rules="{ required: true,  message: 'Campul este obligatoriu', trigger: 'blur', }">
                    <div class="input-group full-width">
                      <!-- {{ Info.ProdusePtSelect }}
                      {{ Info.ProdusePtSelect.length }} -->
                      <el-select size="mini" class="produs-input" 
                        remote
                        :remote-method="handleFindProdus"
                        v-model="selectedObject.Produse[scope.$index].IdProdus"
                        @change="handleSelectProdus(scope.$index)" 
                        :ref="'focusProdus[' + scope.$index + ']'"
                        filterable>
                        <el-option v-for="item in Info.ProdusePtSelect" :key="item.Id" :id="selectedObject.IdGestiune == '' ||
                        selectedObject.IdGestiune == null ||
                        selectedObject.IdGestiune == undefined ? '' : 'produs_cu_stoc'"
                          :name="typeof item.StocFinal != 'undefined' ? 'Stoc: ' + (item.StocFinal) : ''" 
                          :label="item.Nume" 
                          :value="item.Id">
                        </el-option>
                      </el-select>
                      <div v-if="selectedObject.Produse[scope.$index].IdProdus != 0" class="btn-a input-group-append">
                        <el-button tabindex="-1" plain size="mini" type="success" icon="el-icon-plus"
                          @click="show_add_new_produs(scope.$index)" class="success"></el-button>
                      </div>
                    </div>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column min-width="50px" label="Cantitate" style="text-align: center;">
                <template slot-scope="scope">
                  <el-form-item :prop="'Produse[' + scope.$index + '].Cantitate'"
                    :rules="{ required: true, message: 'Campul este obligatoriu', trigger: 'blur',}">
                    <el-input-number size="mini" class="full-width"
                      v-model="selectedObject.Produse[scope.$index].Cantitate" :precision="0" 
                      :min="mode!='stornare' ? 1 : -Infinity" 
                      :step="1"
                      @change="totalProduse()" />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column min-width="70px" label="Cantitate NIR" style="text-align: center;">
                <template slot-scope="scope">
                  <div>
                    <el-form-item
                      :prop="'Produse[' + scope.$index + '].CantitateNir'"
                      :rules="{ required: false, message: 'Campul este obligatoriu', trigger: 'blur',}"
                    >
                      <el-input
                        size="mini" class="full-width"
                        v-model="selectedObject.Produse[scope.$index].CantitateNir"
                        :disabled="true"
                        :precision="0" :min="0" 
                        @change="totalProduse()"
                      />
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="55px" label="P.U. (fara Disc)" style="text-align: center;">
                <template slot-scope="scope">
                  <el-form-item :prop="'Produse[' + scope.$index + '].PretFaraTVAFaraDisc'"
                    :rules="{ required: true,  message: 'Campul este obligatoriu', trigger: 'blur', }">
                    <el-input-number size="mini" class="full-width"
                      v-model="selectedObject.Produse[scope.$index].PretFaraTVAFaraDisc" :precision="2"
                      controls-position="right" :step="0.01" @change="handlePURon(scope.$index)" />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column min-width="50px" label="Valoare (cu Disc)" align="right">
                <template slot-scope="scope">
                  <span
                    v-if=" selectedObject.Produse[scope.$index].ValoareFaraTVACuDisc != 0 
                          && typeof selectedObject.Produse[scope.$index].ValoareFaraTVACuDisc != 'undefined'
                        ">{{selectedObject.Produse[scope.$index].ValoareFaraTVACuDisc}}</span>
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column min-width="60px" label="P.U. Eur (fara Disc)" style="text-align: center;">
                <template slot-scope="scope">
                  <el-form-item :prop="'Produse[' + scope.$index + '].PretFaraTVAFaraDiscEur'"
                    :rules="{ required: true,  message: 'Campul este obligatoriu', trigger: 'blur', }">
                    <el-input-number size="mini" class="full-width"
                      v-model="selectedObject.Produse[scope.$index].PretFaraTVAFaraDiscEur" :precision="4"
                      controls-position="right" :step="0.0001" @change="handlePUEur(scope.$index)" />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column  :tabindex="-1" min-width="40px" label="Cota TVA" style="text-align: center;">
                <template slot-scope="scope">
                  <el-form-item  :tabindex="-1" :prop="'Produse[' + scope.$index + '].IdTVA'"
                    :rules="{ required: true,  message: 'Campul este obligatoriu', trigger: 'blur', }">
                    <el-select  :tabindex="-1" size="mini" class="full-width select_dreapta"
                      v-model="selectedObject.Produse[scope.$index].IdTVA" >
                      <el-option v-for="item in Info.listaTVA" :key="item.Id" :label="item.Procent" :value="item.Id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column style="text-align: center;" fixed="right" label="Actiuni" width="100px">
                <template slot-scope="scope">
                  <span>
                    <el-tooltip content="Sterge"  :tabindex="-1" >
                      <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="sterge(scope.$index)" />
                    </el-tooltip>
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <el-button @click="rand_nou()"> + </el-button>
            <el-row v-if="selectedObject.arata_total == true">
              <el-col class="tabel-valori">
                <table cellpadding="10" class="tabel-data" style="margin-top: 10px; width: 70%" :key="refreshTotaluri">
                  <tr class="cell-data">
                    <th>Valoare totala fara TVA</th>
                    <td align="right" > {{ selectedObject.ValoareFaraTVACuDisc | format_money }}</td>
                  </tr>
                  <tr class="cell-data">
                    <th>Valoare TVA</th>
                    <td align="right" > {{ selectedObject.ValoareTVA | format_money }} </td>
                  </tr>
                  <tr class="cell-data">
                    <th>Valoare totala cu TVA</th>
                    <td align="right" > {{ selectedObject.ValoareCuTVACuDisc | format_money }} </td>
                  </tr>
                  <tr v-if="selectedObject.Discount > 0" class="cell-data">
                    <th>Discount</th>
                    <td align="right" > {{ selectedObject.Discount}}% </td>
                  </tr>
                </table>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="resetForm('my-form')"> Renunta </el-button>
      <el-button type="primary" @click="save('0')"> Salveaza </el-button>
      <el-button type="primary" @click="save('1')"> Salveaza si imprima </el-button>
    </span>
    <!-- <pre>{{ this.selectedObject }}</pre> -->
    <Delegati-dialog ref="dlg-delegat-nou" @save="salvare_delegat" />
    <Agenti-dialog ref="dlg-agent-nou" @save="salvare_agent" />
    <Produse-dialog ref="dlg-produs-nou" @save="salvare_produs" />
    <Clienti-dialog ref="dlg-client-nou" @save="salvare_client" />
    <Transport-dialog ref="dlg-transport-nou" @save="salvare_transport" />
  </el-dialog>
</template>

<script>
import moment from "moment";
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Agenti_dialog from "@/pages/agenti/Agenti_dialog.vue";
import Produse_dialog from "@/pages/produse/Produse_dialog.vue";
import Clienti_dialog from "@/pages/clienti/Clienti_dialog.vue";
import Delegati_dialog from "@/pages/delegati/Delegati_dialog.vue";
import Transport_dialog from "@/pages/transport/Transport_dialog.vue";
import AppVue from '../../App.vue';

export default {
  name: "Facturi_dialog",
  extends: BasePage,
  components: {
    "Delegati-dialog": Delegati_dialog,
    "Agenti-dialog": Agenti_dialog,
    "Produse-dialog": Produse_dialog,
    "Clienti-dialog": Clienti_dialog,
    "Transport-dialog": Transport_dialog,
  },
  data() {

    let validateCurs = async (rule, value, callback) => {
      if ( value > 0) {
        callback();
      } else {
        callback(new Error("Cursul trebuie sa fie mai mare ca 0"))
      }
    }

    return {
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date()
        },
      },
      Eroare: false,
      factureazaAviz: 0,
      tvaImplicit: {},
      refreshTable: 0,
      refreshTotaluri: 0,
      showPopup: false,
      mode: "add",
      selectedObject: {
        produse :[]
      },
      selectedObjectTemplate: {
        Internet: "0",
        DataFactura: "",
        IdSerie: "",
        Numar: "",
        IdClient: "",
        Curs: "0",
        IdDelegat: "-1",
        IdAgent: "-1",
        IdGestiune: "1",
        IdTransport: "-1",
        Scadenta: null,
        Discount: 0,
        NumeClient: "",
        CifClient: "",
        RegComClient: "",
        Produse: [],
        TermenPlataDefaultClient: "",
        IdGestiuneDefaultClient: "",
        arata_total: false,
      },
      produsTemplate: {
        IdProdus: "",
        NumeProdus: "",
        Cantitate: "",
        PretFaraTVACuDisc: "",
        PretFaraTVAFaraDisc: "",
        PretCuTVAFaraDisc: "",
        PretCuTVACuDisc: "",
        PretFaraTVAEur: "",
        IdTVA: "",
        ProcentTVA: "",
        ValoareTVA: "",
        ValoareCuTVA: "",
        ValoareFaraTVACuDisc: "",
        ValoareFaraTVAFaraDisc: "",
      },
      Info: {
        listaTVA: [],
        gestiuni: [],
        clienti: [],
        // clientiOld: [],
        delegati: [],
        transportatori: [],
        conturi_bancare: [],
        produse: [],
        ProdusePtSelect: [],
        ProduseExistentePtSelect: [],
      },
      rules: {
        IdContBancar: [{ required: true, message: "Campul este obligatoriu" }],
        TipIncasare: [{ required: true, message: "Campul este obligatoriu" }],
        Curs: [{ required: true, validator: validateCurs }],
        Numar: [{ required: true, message: "Campul este obligatoriu" }],
        IdSerie: [{ required: true, message: "Campul este obligatoriu" }],
        IdGestiune: [{ required: true, message: "Campul este obligatoriu" }],
        DataFactura: [{ required: true, message: "Campul este obligatoriu" }],
        NumeClient: [{ required: true, message: "Campul este obligatoriu" }],
        Gestiune: [{ required: true, message: "Campul este obligatoriu" }],
        IdProdus: [{ required: true, message: "Campul este obligatoriu" }],
        PretFaraTVAFaraDisc: [{ required: true, message: "Campul este obligatoriu" }],
        Cantitate: [{ required: true, message: "Campul este obligatoriu" }],
        IdTVA: [{ required: true, message: "Campul este obligatoriu" }],
      },
    };
  },
  watch: {
    'selectedObject.DataCurs'(newselectedObject, oldselectedObject) {
      // if (typeof oldselectedObject == 'undefined' && this.selectedObject.DataCurs != null) {
        if(this.selectedObject.DataCurs != null) {
        this.getCurs(newselectedObject)
      }
    }
  },
  methods: {
    show_stornare: async function (id) {
      this.mode = "stornare";
      var result = await this.post("facturi/get_info_item_dialog", { id: id, stornare: true});
      this.selectedObject = result.Item;
      this.selectedObject.NumeSerieFacInitiala = result.Serie.Serie;
      await this.handleInternet('get');
      this.totalProduse();

      this.minim1Produs();
      this.handleSelectSerie();
      await this.handleSelectGestiune();
      await this.seteazaSelect();
      this.showPopup = true;

    },
      
    show_me: async function (id, idAviz) {

      this.selectedObject.Produse = [];
      await this.get_info();

      this.selectedObject = {...this.selectedObjectTemplate};

      if (id == null) {
        this.mode = "add";
        if(idAviz != null) {
          this.factureazaAviz = this.selectedObject.Id;
          var result = await this.post("avize/get_info_item_dialog", { id: idAviz });
          this.selectedObject = result.Item;
          this.selectedObject.IdGestiune = this.selectedObject.IdGestiuneDestinatie;
          this.selectedObject.IdAviz  = result.Item.Id;
          delete this.selectedObject.Id;

          // this.Info.produseToateGestiunile = this.Info.raspuns_produseToateGestiunile;

          // this.handleSelectGestiune();
          await this.seteazaSelect();
          await this.handleInternet('get');
          this.totalProduse();
        } else {        
          // this.Info.produseToateGestiunile = this.Info.raspuns_produseToateGestiunileDoarStoc;
          this.selectedObject =  {...this.selectedObjectTemplate};
          this.selectedObject.DataFactura = moment().format('YYYY-MM-DD');
          var result = await this.post("luni_blocate/verifica_data_intrare", { data: this.selectedObject.DataFactura });
          if(result.StatusLuna == 0){
            this.selectedObject.DataFactura = '';
          }
        }

        // delete this.selectedObject.IdSerie;
        if (this.IdSerieImplicitaUser > 0) this.selectedObject.IdSerie      = this.IdSerieImplicitaUser;
        if (this.IdContImplicit       > 0) this.selectedObject.IdContBancar = this.IdContImplicit;
        this.handleSelectSerie();


        

      } else {
        this.mode = "edit";
        
        // this.Info.produseToateGestiunile = this.Info.raspuns_produseToateGestiunile;
        var result = await this.post("facturi/get_info_item_dialog", { id: id });
        this.selectedObject = result.Item;
        
        await this.seteazaSelect();

        await this.handleInternet('get');
        this.totalProduse();
      }
      this.minim1Produs();
      await this.handleSelectGestiune();
      this.showPopup = true;
      this.$nextTick(()=> {
          this.$refs['focusMe'].focus();
        })
    },

    async seteazaSelect() {

      this.Info.ProduseExistentePtSelect = [];
      this.selectedObject.Produse.forEach((el) => {
          let produs_deja_in_select = this.Info.ProduseExistentePtSelect.filter((xxx) => xxx.Id == el.Id)[0];
          if (!this.Info.ProduseExistentePtSelect.includes(produs_deja_in_select) ){
            this.Info.ProduseExistentePtSelect.push(el);
          }
        });

      this.Info.ProdusePtSelect = this.Info.ProduseExistentePtSelect;
      this.Info.ProduseExistentePtSelect = [];
    },
    async getCurs(dataCurs) {
      var response = await this.post("curs_bnr/getCurs", { data: dataCurs })
      if (response.fx) {
        this.selectedObject.Curs = response.fx;
      } else {
        delete this.selectedObject.DataCurs;
      }
      this.totalProduse();
    },

    async get_info() {
      var response = await this.post("facturi/get_info_for_dialog");
      // this.Info  = response;
      // this.Info.clientiOld = [...response.clienti];
      this.Info.agenti = response.agenti;
      this.Info.listaTVA = response.listaTVA;
      this.Info.gestiuni = response.gestiuni;
      this.Info.delegati = response.delegati;
      this.Info.serii = response.serii;
      this.Info.transportatori = response.transportatori;
      this.Info.conturi_bancare = response.conturi_bancare;
      // this.Info.produse = response.produse;
      // this.Info.clienti = response.clienti;
      // this.Info.produseToateGestiunile = response.produseToateGestiunile;
      // this.Info.raspuns_produseToateGestiunileDoarStoc = response.produseToateGestiunileDoarStoc;
      // this.Info.raspuns_produseToateGestiunile = response.produseToateGestiunile;
      this.IdSerieImplicitaUser = response.User.IdSerieImplicita;






      this.IdContImplicit = response.User.IdContImplicit;
      this.tvaImplicit = this.Info.listaTVA.filter((item) => item.Implicit == 1)[0];
    },

    selecteazaDataCurs() {
      this.$refs['refDataCurs'].pickerVisible = true;
    },

    handleChangeCurs() {
      delete this.selectedObject.DataCurs;
      this.totalProduse();
    },
    totalProduse() {

      if(typeof this.selectedObject.Discount == 'undefined') this.selectedObject.Discount = 0;

      if (this.selectedObject.Produse.length > 0) {
        var TotalValoareCuTvaCuDisc = [];
        var TotalValoareTVA = [];
        var TotalValoareFaraTvaCuDisc = [];
        var TotalCantitateNir = [];


        if (this.selectedObject.CostTransport == undefined) this.selectedObject.CostTransport = 0;
        if (this.selectedObject.CostManipulare == undefined) this.selectedObject.CostManipulare = 0;


        this.selectedObject.Produse.forEach((el) => {

          let CantitateAmbalare = 1;
          if(el.CantitateAmbalare > 0) CantitateAmbalare = el.CantitateAmbalare;
          el.CantitateNir = CantitateAmbalare * el.Cantitate;

          el.PretFaraTVACuDisc = parseFloat(el.PretFaraTVAFaraDisc  * (100 - this.selectedObject.Discount) / 100).toFixed(2);

          el.ValoareFaraTVACuDisc = parseFloat(el.PretFaraTVAFaraDisc * el.CantitateNir * (100 - this.selectedObject.Discount) / 100).toFixed(2);
          el.ValoareTVA = parseFloat((el.ValoareFaraTVACuDisc * (0 + "." + el.ProcentTVA))).toFixed(2);
         
          el.PretCuTVAFaraDisc = Number(el.PretFaraTVAFaraDisc) + Number(el.ValoareTVA);
         
          el.ValoareCuTVACuDisc = Number(el.ValoareFaraTVACuDisc) + Number(el.ValoareTVA);
          el.PretFaraTVAFaraDisc = el.PretFaraTVAFaraDiscEur * this.selectedObject.Curs

          TotalValoareFaraTvaCuDisc.push(parseFloat(el.ValoareFaraTVACuDisc).toFixed(2));
          TotalValoareCuTvaCuDisc.push(parseFloat(el.ValoareCuTVACuDisc).toFixed(2));
          TotalValoareTVA.push(parseFloat(el.ValoareTVA).toFixed(2));
          TotalCantitateNir.push(parseFloat(el.CantitateNir).toFixed(2));


        });

        this.selectedObject.CantitateNirTotala = TotalCantitateNir.reduce((a, b) => Number(a) + Number(b));
        this.selectedObject.ValoareCuTVACuDisc = TotalValoareCuTvaCuDisc.reduce((a, b) => Number(a) + Number(b));
        this.selectedObject.ValoareFaraTVACuDisc = TotalValoareFaraTvaCuDisc.reduce((a, b) => Number(a) + Number(b));
        this.selectedObject.ValoareTVA = TotalValoareTVA.reduce((a, b) => Number(a) + Number(b));
        this.selectedObject.arata_total = true;


        
        this.selectedObject.ValoareFaraTVA += this.selectedObject.CostTransport / this.selectedObject.Curs
        this.selectedObject.ValoareFaraTVA += this.selectedObject.CostManipulare / this.selectedObject.Curs

        this.selectedObject.Produse.forEach((el) => {

          el.CostManipulareProdus =  this.selectedObject.CostManipulare / this.selectedObject.Curs  / this.selectedObject.CantitateNirTotala * el.CantitateNir;
          el.CostTransportProdus  =  this.selectedObject.CostTransport / this.selectedObject.Curs  / this.selectedObject.CantitateNirTotala * el.CantitateNir;

          el.PretFaraTVAEurFinal +=  (el.CostManipulareProdus + el.CostTransportProdus)  / el.CantitateNir;
          
        });

        this.selectedObject.Produse.push({})
        this.selectedObject.Produse.pop()

        // this.refreshTable++;
        this.refreshTotaluri++;
      }
    },
    minim1Produs() {
      if (this.selectedObject.Produse.length == 0) {
        this.rand_nou()
      }
    },
    async rand_nou() {
      let nou = {
        Id: "",
        Cantitate: 1,
        PretCuTVAFaraDisc: 0,
        PretCuTVACuDisc: 0,
        ValoareTVA: 0,
        ValoareCuTVACuDisc: 0,
        IdTVA: this.tvaImplicit.Id,
        ProcentTVA: parseFloat(this.tvaImplicit.Procent).toFixed(0),
      };
      this.selectedObject.Produse.push(nou);
      this.refreshTable++;

      this.$nextTick(()=> {
        let indexNou = this.selectedObject.Produse.length - 1;
        // console.log(this.$refs['focusProdus['+ indexNou +']'])
        if(indexNou > 0) this.$refs['focusProdus['+ indexNou +']'].focus()
      })
    },
    async sterge(row) {
      this.$confirm(`Sunteti sigur ?`, "Warning", {
        type: "warning",
      })
        .then(async () => {
          this.Info.ProduseExistentePtSelect.forEach((el, index) => {
            if(this.selectedObject.Produse[row].Id == el.Id){
             if(this.selectedObject.Produse.filter((prod) => prod.Id == el.Id).length == 1){ // sterg doar daca e produsul 1 singura data
              this.Info.ProduseExistentePtSelect.splice(index, 1); // sterg din ProduseExistentePtSelect
              this.Info.ProdusePtSelect = this.Info.ProdusePtSelect.filter((prod) => prod.Id != el.Id); // sterg din ProdusePtSelect
             }
            }
          })

          this.selectedObject.Produse.splice(row, 1);
          this.totalProduse();
          this.minim1Produs();

          this.refreshTable++;
        })
        .catch(() => {
          // this.$message({type: 'info', message: 'Stergere anulata'});
        });
    },

    show_add_new_client() {
      this.$refs["dlg-client-nou"].show_me();
    },
    show_add_new_transport() {
      this.$refs["dlg-transport-nou"].show_me();
    },
    show_add_new_produs(index) {
      this.$refs["dlg-produs-nou"].show_me(null, index);
    },
    show_add_new_delegat() {
      this.$refs["dlg-delegat-nou"].show_me();
    },
    show_add_new_agent() {
      this.$refs["dlg-agent-nou"].show_me();
    },
    async salvare_client(insertedObj) {
      await this.get_info();
      this.handleSelectClient(insertedObj.Id)
      this.totalProduse();
    },
    async salvare_transport(insertedObj) {
      await this.get_info();
      this.selectedObject.IdTransport = String(insertedObj.Id);
    },
    async salvare_delegat(insertedObj) {
      await this.get_info();
      this.selectedObject.IdDelegat = String(insertedObj.Id);
    },
    async salvare_agent(insertedObj) {
      await this.get_info();
      this.selectedObject.IdAgent = String(insertedObj.Id);
    },
    async handlePURon(item) {
      if(this.selectedObject.Produse[item].PretFaraTVAFaraDiscEur > 0){
        this.selectedObject.Produse[item].PretFaraTVACuDiscEur   = this.selectedObject.Produse[item].PretFaraTVACuDisc / this.selectedObject.Curs;
        this.selectedObject.Produse[item].PretFaraTVAFaraDiscEur = this.selectedObject.Produse[item].PretFaraTVAFaraDisc / this.selectedObject.Curs;
      }
      this.totalProduse();
    },    
    async handlePUEur(item) {
      this.selectedObject.Produse[item].PretFaraTVACuDisc   = this.selectedObject.Produse[item].PretFaraTVACuDiscEur * this.selectedObject.Curs;
      this.selectedObject.Produse[item].PretFaraTVAFaraDisc = this.selectedObject.Produse[item].PretFaraTVAFaraDiscEur * this.selectedObject.Curs;
      this.totalProduse();
    },
    async salvare_produs(insertedObj) {
      await this.get_info();
      this.selectedObject.Produse[insertedObj.numar_linie].IdProdus = String(insertedObj.Id);
      this.selectedObject.Produse[insertedObj.numar_linie].NumeProdus = insertedObj.Nume;
      // this.Info.ProdusePtSelect = this.Info.produseToateGestiunile[this.selectedObject.IdGestiune];
      this.handleSelectGestiune();
      this.handleSelectProdus(insertedObj.numar_linie);
      this.refreshTable++;
    },

    handleSelectProdus(item) {

      var idProdusSelectat = this.selectedObject.Produse[item].IdProdus;
      if (typeof idProdusSelectat !== 'undefined') {
        let produs = this.Info.ProdusePtSelect.filter((prod) => prod.Id == idProdusSelectat)[0];
        // console.log(this.Info.produse)
        if(typeof produs.NumeProdus != undefined) this.selectedObject.Produse[item].NumeProdus = produs.NumeProdus;
        if(typeof produs.Nume != undefined) this.selectedObject.Produse[item].NumeProdus = produs.Nume;

        if (this.selectedObject.Internet == 1) {
          this.selectedObject.Produse[item].PretFaraTVAFaraDisc = produs.PretInternet * this.selectedObject.Curs;
          // this.selectedObject.Produse[item].PretFaraTVAFaraDiscEur = produs.PretInternet;


        } else {
          let pretPersonalizat = this.Info.ProdusePtSelect.filter((prod) => prod.Id == this.selectedObject.Produse[item].IdProdus)[0].personalizari;
          let pret_client_selectat = pretPersonalizat.filter((xxx) => xxx.IdClient == this.selectedObject.IdClient);
          if (pret_client_selectat.length > 0 && pretPersonalizat.length > 0 && this.selectedObject.IdClient > 0) {
            this.selectedObject.Produse[item].PretFaraTVAFaraDisc = pret_client_selectat[0].PretPersonalizat * this.selectedObject.Curs;
            // this.selectedObject.Produse[item].PretFaraTVAFaraDiscEur = pret_client_selectat[0].PretPersonalizat;
          } else {
            this.selectedObject.Produse[item].PretFaraTVAFaraDisc = produs.Pret * this.selectedObject.Curs;
            // this.selectedObject.Produse[item].PretFaraTVAFaraDiscEur = produs.Pret;
          }
        }
        // if (this.selectedObject.Produse[item].IdGestiune == 0) {
        //   if (this.selectedObject.IdGestiuneDefaultClient > 0) {
        //     this.selectedObject.Produse[item].IdGestiune = this.selectedObject.IdGestiuneDefaultClient;
        //   } else {
        //     this.selectedObject.Produse[item].IdGestiune = "";
        //   }
        // }

        this.selectedObject.Produse[item].PretFaraTVAFaraDiscEur = this.selectedObject.Produse[item].PretFaraTVAFaraDisc / this.selectedObject.Curs
        this.selectedObject.Produse[item].PretFaraTVACuDiscEur   = this.selectedObject.Produse[item].PretFaraTVACuDisc   / this.selectedObject.Curs


        this.selectedObject.Produse[item].Id = this.selectedObject.Produse[item].IdProdus
        this.selectedObject.Produse[item].Nume = this.selectedObject.Produse[item].NumeProdus

        if(this.Info.ProduseExistentePtSelect.filter((rrr) => rrr.Id == idProdusSelectat).length == 0){
          this.Info.ProduseExistentePtSelect.push(produs);
          // console.log(this.selectedObject.Produse[item])
          // this.Info.ProduseExistentePtSelect.push(this.selectedObject.Produse[item]);
        }
        

      
          
          
        // }

        // // this.Info.ProdusePtSelect = this.Info.ProduseExistentePtSelect;
        // this.$set(this.Info, 'ProdusePtSelect', this.Info.ProduseExistentePtSelect)
      }
      
      // this.selectedObject.Produse.splice(this.selectedObject.Produse.length)
      
      // this.$set(this.Info, 'ProdusePtSelect', [])


      // this.Info.ProdusePtSelect.splice(this.Info.ProdusePtSelect.length)
      this.Eroare = false;


      this.totalProduse();
    },
 
    handleSelectTVA(item) {
      this.selectedObject.Produse[item].ProcentTVA = this.Info.listaTVA[this.selectedObject.Produse[item].IdTVA - 1].Procent;
      this.totalProduse();
    },
    async handleSelectGestiune() {


      if(this.Info.ProdusePtSelect.length > 0){
        this.Info.ProdusePtSelect.forEach((el) => {
          delete el.StocFinal;
        })
      }

      // if(this.selectedObject.IdGestiune > 0){

      //   let include_stoc_zero = true;
      //   let include_dezactivate = false;

      //   if(this.mode == "add"){ // daca e factura noua(indiferent daca factureaza aviz sau nu)
      //     include_stoc_zero = false;
      //     include_dezactivate = false;
      //   // } else if(this.mode == "add" && this.factureazaAviz > 0){// daca e factura noua si factureaza aviz
      //   //   include_stoc_zero = true;
      //   //   include_dezactivate = false;
      //   } else { // daca este edit sau storno
      //     include_stoc_zero = true;
      //     include_dezactivate = true;
      //   }

      //   var result = await this.post("produse/get_stoc_gestiune", 
      //     { idGestiune: this.selectedObject.IdGestiune , include_stoc_zero: include_stoc_zero, include_dezactivate: include_dezactivate});
      //   if(result){
      //     this.Info.ProdusePtSelect = result.stoc_gestiune;
      //     this.selectedObject.Produse.splice(this.selectedObject.Produse.length)
      //   }
      // }
  
    },
    handleSelectInternet() {
      this.selectedObject.Produse.forEach((el, index) => {
        this.handleSelectProdus(index)
      });
      this.totalProduse();
    },
    async handleSelectSerie() {
      // if (this.mode == 'edit' && this.selectedObject.IdSerie == this.selectedObject.IdSerieInitiala) {
      //   this.selectedObject.Numar = this.selectedObject.NumarInitial;
      // } else {
        if(this.selectedObject.IdSerie > 0){
          var response = await this.post("facturi/get_numar_serie", { id_serie: this.selectedObject.IdSerie })
          this.selectedObject.Numar = response.NumarNou;
        }
      // }
    },
    handleInternet: async function (mode) {
      if (mode === 'save') {
        if (this.selectedObject.Internet) {
          this.selectedObject.Internet = '1';
        } else {
          this.selectedObject.Internet = '0'
        }
      } else if (mode === 'get') {
        if (this.selectedObject.Internet == '1') {
          this.selectedObject.Internet = true;
        } else {
          this.selectedObject.Internet = false;
        }
      }
    },

    // async handleFind(data) {
    //   this.Info.clienti = [];
    //   if (data != "") {
    //     this.Info.clientiOld.forEach(element => {
    //       if ((element.Nume).toLowerCase().indexOf(data) != -1 || (element.CodFiscal != null && (element.CodFiscal).indexOf(data) != -1)) {
    //         this.Info.clienti.push(element);
    //       }
    //     })
    //   } else {
    //     this.Info.clienti = this.Info.clientiOld.slice();
    //   }
    // },

    async  handleFind( data) {

      if (data !== '') {
        await new Promise(resolve => setTimeout(resolve, 200)); 
        this.Info.clienti = [];
        this.loading = true;

        var result = await this.post("clienti/remoteFindClient", { string: data });
        if(typeof result.ClientiFiltrati !== 'undefined'){
          this.Info.clienti = result.ClientiFiltrati;
        }

      }
    },    
    async  handleFindProdus(data) {
      if (data !== '') {
        await new Promise(resolve => setTimeout(resolve, 200)); 
        this.Info.clienti = [];
        this.loading = true;

        var result = await this.post("produse/remoteFindProdus", { string: data,idGestiune: this.selectedObject.IdGestiune });
        if(typeof result.ProduseFiltrate !== 'undefined'){
          
          // this.Info.ProdusePtSelect o sa contina rezultatul cautarii + produsele deja alese  
          this.Info.ProdusePtSelect = this.Info.ProduseExistentePtSelect.concat(result.ProduseFiltrate)

          this.Info.ProdusePtSelect = result.ProduseFiltrate;
          this.Info.ProduseExistentePtSelect.forEach((el, index) => {
            if(result.ProduseFiltrate.filter((yyy) => yyy.Id == el.Id).length == 0){
              this.Info.ProdusePtSelect.push(el)
            }
          })
        }

      }
    },

    handleSelectClient(idFz) {

      let item = this.Info.clienti.filter((client) => client.Id == idFz)[0];
      if (parseInt(item.id) < 0) {
        this.selectedObject.NumeClient = "";
      } else {
        var client = item;
        this.selectedObject.IdClient = client.Id;
        this.selectedObject.CifClient = client.CodFiscal;
        this.selectedObject.RegComClient = client.RegCom;
        this.selectedObject.DescriereClient = client.Descriere;
        this.selectedObject.NumeClient = client.Nume;
        this.selectedObject.TermenPlataDefaultClient = Number(client.TermenPlata);
        this.selectedObject.DiscountDefaultClient = Number(client.Discount);
        this.selectedObject.Discount = this.selectedObject.DiscountDefaultClient;
        if (client.IdGestiuneAvizareDefault !== null && (this.selectedObject.IdGestiune == '' || this.selectedObject.IdGestiune == '1') && client.IdGestiuneAvizareDefault !== 0 && typeof client.IdGestiuneAvizareDefault !== 'undefined') {
          // console.log("client.IdGestiuneDefault", client.IdGestiuneAvizareDefault);
          this.selectedObject.IdGestiune = client.IdGestiuneAvizareDefault;
        } 

        // this.Info.ProdusePtSelect = this.Info.produseToateGestiunile[this.selectedObject.IdGestiune]
        this.handleSelectGestiune();
        this.selectedObject.Produse.forEach((el, index) => {
          this.handleSelectProdus(index)
        });
        
        this.selectedObject.Produse.splice(this.selectedObject.Produse.length)

        if (this.selectedObject.DataFactura && this.selectedObject.TermenPlataDefaultClient > 0) {
          var scadenta = moment(this.selectedObject.DataFactura).add(this.selectedObject.TermenPlataDefaultClient, 'days');
          this.selectedObject.Scadenta = scadenta.format('YYYY-MM-DD');
        }

        this.totalProduse();

      }
    },
    async handleSelectDataFactura() {
     
      var result = await this.post("luni_blocate/verifica_data_intrare", { data: this.selectedObject.DataFactura });
      if(result.StatusLuna == 0){
        var data =  moment(this.selectedObject.DataFactura);
        var mesaj =  'Luna ' + this.$options.filters.LunaLitere(data.format('M')) + ' ' +  data.format('Y') + ' este inchisa!';
        this.$notify({
            title: "Atentie",
            message: mesaj,
            dangerouslyUseHTMLString: true,
            type: "warning",
            duration: 5000,
          })

        this.selectedObject.DataFactura = '';

        this.$nextTick(()=> {
          this.$refs['focusData'].focus();
        })

      }


      if (this.selectedObject.DataFactura && this.selectedObject.TermenPlataDefaultClient > 0) {
        var scadenta = moment(this.selectedObject.DataFactura).add(this.selectedObject.TermenPlataDefaultClient, 'days');
        this.selectedObject.Scadenta = scadenta.format('YYYY-MM-DD');
      }
    },
    save: async function (andPrint) {
      this.$refs["my-form"].validate(async (valid) => {
        if (valid) {
          this.handleInternet('save');
          let response = await this.post("facturi/save", {
            mode: this.mode,
            object: this.selectedObject,
          });
          if(typeof response.Id != 'undefined' && andPrint == 1){
            let IdDePrintat = response.Id; 
            let res = await this.post('facturi/print_facturi', {IdFactura: IdDePrintat});

            let doc = window.open("", "_blank");
            doc.document.write(res.html);
          }
          this.Eroare=false;
          if (response.Success){
            
            this.$swal({
              toast: true,
              icon: 'success',
              title: response.mesajSalvare,
              showConfirmButton: false,
              timer: 4500,
              showCloseButton: true,
              timerProgressBar: true,
              width: 400,
            });
            this.resetForm();
            this.$emit("save");
          } else {
            this.$notify({
              title: "Eroare la salvare",
              message: 'Verificati daca exista stoc suficient pentru toate produsele!',
              dangerouslyUseHTMLString: true,
              type: "warning",
              duration: 5000,
            })
            this.Eroare=true;
            this.selectedObject.Produse = response.Produse;
            this.handleSelectGestiune();
            

          }
        } else console.log("eroare validare formular!");
      });
    },    

    resetForm() {
      this.$refs["my-form"].resetFields();
      this.selectedObject = this.selectedObjectTemplate;
      this.showPopup = false;
    },
    focusChanged (event) {
      const allElements = document.querySelectorAll('*');
      allElements.forEach((element) => {
        element.classList.remove('selectat');
      });

      const el = event.target
      el.classList.add("selectat");
    }
  },
  mounted: function () {
    this.get_info();
  },
  created() {
    document.addEventListener('focusin', this.focusChanged)
  },
  beforeDestroy() {
    document.removeEventListener('focusin', this.focusChanged)
  },

};

</script>

<style lang="less" scoped>
#client_cif {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

#client_cif:after {
  content: 'CIF: ' attr(name);
  width: auto;
  max-height: 27px !important;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  font-size: 10px !important;
  padding: 0 5px;
  margin: 2px 0;
  border: 1px solid transparent;
  border-radius: 7px;
  background-color: #40a0ffa1;
}

#produs_cu_stoc:after {
  content: attr(name);
  width: auto;
  max-height: 27px !important;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  font-size: 10px !important;
  padding: 0 5px;
  margin: 2px 0;
  border: 1px solid transparent;
  border-radius: 7px;
  background-color: #40a0ffa1;
}

/deep/ .el-input-number .el-input__inner {
  text-align: right;
}

/deep/ .select_dreapta .el-input__inner {
  text-align: right;
}

.full-width {
  width: 100% !important;

  .el-input {
    width: 85% !important;
  }

  .el-input-number {
    width: 85% !important;
  }

  .el-select {
    width: 85% !important;
  }


  .produs-input .el-select {
    width: 100% !important;
  }



  .el-date-editor.el-input {
    width: 100% !important;
  }

  .btn-a {
    margin: 0;
    padding: 0;
    width: 15% !important;

    .el-button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.el-table .el-form-item {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}


.tabel-valori {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.tabel-data {
  width: 300px !important;
  word-break: keep-all;

}

/deep/ .el-checkbox__input.is-focus{
  border: 3px solid yellow !important;
  border-radius: 5px;
}
/deep/ .selectat{
  background-color: yellow !important;
}



@media only screen and (max-width: 768px) {
  .tabel-data {
    width: 75% !important;
    font-size: 14px;
  }
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>